var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "header-title"
  }, [_c('div', {
    staticClass: "pay-name"
  }, [_c('div', {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "550"
    }
  }, [_vm._v("付款给" + _vm._s(_vm.projectName))]), _c('div', {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("费用类型：" + _vm._s(_vm.typeName))])]), _c('div', {
    staticClass: "pay-img"
  }, [_c('van-image', {
    attrs: {
      "width": "40",
      "height": "40",
      "radius": "5",
      "src": require('../../assets/images/payExpence/erjia.png')
    }
  })], 1)]), _c('div', {
    staticClass: "pay-content"
  }, [_c('div', {
    staticClass: "pay-title"
  }, [_vm._v("付款金额")]), _c('div', {
    staticClass: "pay-amount"
  }, [_c('span', {
    staticClass: "pay-icon"
  }, [_vm._v("¥")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.amount,
      expression: "form.amount"
    }],
    ref: "scanTextbox",
    staticClass: "pay-input",
    attrs: {
      "readonly": true
    },
    domProps: {
      "value": _vm.form.amount
    },
    on: {
      "click": _vm.stopKeyborad,
      "blur": function ($event) {
        _vm.show = false;
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "amount", $event.target.value);
      }
    }
  })]), _c('div', [_vm.form.remark === '' ? _c('div', {
    staticClass: "add-remark",
    on: {
      "click": function ($event) {
        _vm.showRemark = true;
      }
    }
  }, [_vm._v(" 添加备注 ")]) : _c('div', {
    staticClass: "remark"
  }, [_vm._v(" " + _vm._s(_vm.form.remark) + " "), _c('span', {
    staticClass: "update",
    on: {
      "click": function ($event) {
        _vm.showRemark = true;
      }
    }
  }, [_vm._v("修改")])])]), _c('div', {
    staticClass: "payment"
  })]), _c('van-dialog', {
    attrs: {
      "title": "添加备注",
      "show-cancel-button": ""
    },
    on: {
      "confirm": _vm.onConfirm
    },
    model: {
      value: _vm.showRemark,
      callback: function ($$v) {
        _vm.showRemark = $$v;
      },
      expression: "showRemark"
    }
  }, [_c('div', {
    staticClass: "remark-input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.remark,
      expression: "remark"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.remark
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.remark = $event.target.value;
      }
    }
  })])]), _c('van-number-keyboard', {
    attrs: {
      "show": _vm.show,
      "theme": "custom",
      "extra-key": ['.'],
      "close-button-text": "付款"
    },
    on: {
      "blur": function ($event) {
        _vm.show = false;
      },
      "input": _vm.numberInput,
      "delete": _vm.onDelete,
      "close": _vm.onClose
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };