import "core-js/modules/es.array.push.js";
import { getProjectName, selectTypeName, tenantPayOtherFeeNonHouse } from '@api/expense';
import { removeStorage, setStorage, getStorage } from '@utils/storage';
import { prohibitNegative } from '@utils/utils';
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      form: {
        amount: '',
        remark: ''
      },
      remark: '',
      typeName: '',
      projectName: '',
      projectId: '',
      showPicker: false,
      showRemark: false,
      show: false,
      columns: []
      // contractData: []
    };
  },

  computed: {},
  watch: {},
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    async initData() {
      let url = window.location.href;
      let obj = {},
        index = url.indexOf('?'),
        // 看url有没有参数
        params = url.substr(index + 1); // 截取url参数部分 name = aaa & age = 20

      if (index !== -1) {
        // 有参数时
        let parr = params.split('&'); // 将参数分割成数组 ["name = aaa", "age = 20"]
        for (let i of parr) {
          // 遍历数组
          let arr = i.split('='); // 1） i name = aaa   arr = [name, aaa]  2）i age = 20  arr = [age, 20]
          obj[arr[0]] = arr[1]; // obj[arr[0]] = name, obj.name = aaa   obj[arr[0]] = age, obj.age = 20
        }

        this.form.amount = getStorage('amount') || '';
        this.form.remark = getStorage('remark') || '';
        this.remark = getStorage('remark') || '';
        let {
          projectId,
          type
        } = obj;
        this.projectId = projectId;
        getProjectName({
          projectId
        }).then(res => {
          let result = res.data;
          if (result.code === '10000') {
            console.log(result.data, '1');
            this.projectName = result.data;
          }
        });
        selectTypeName({
          type
        }).then(res => {
          let result = res.data;
          if (result.code === '10000') {
            this.typeName = result.data;
            document.title = this.typeName + '-' + '费用缴纳';
          }
        });
      }
    },
    numberInput(e) {
      let amount = this.form.amount + e;
      this.form.amount = this.prohibitNegative(amount, 2, 8);
      setStorage('amount', this.form.amount);
      console.log(this.form.amount);
    },
    onDelete() {
      this.form.amount = this.form.amount.slice(0, this.form.amount.length - 1);
      setStorage('amount', this.form.amount);
    },
    onClose() {
      if (this.form.amount === '') {
        this.$toast('请输入金额');
      } else {
        this.onSubmit();
      }
    },
    stopKeyborad() {
      this.show = true;
      this.$refs.scanTextbox.setAttribute('readonly', 'readonly');
      if (this.$refs.scanTextbox) {
        this.$refs.scanTextbox.focus();
        this.$refs.scanTextbox = null;
      }
      setTimeout(() => {
        if (this.$refs.scanTextbox) {
          this.$refs.scanTextbox.removeAttribute('readonly');
          this.$refs.scanTextbox = null;
        }
      }, 200);
    },
    onConfirm() {
      this.form.remark = this.remark;
      setStorage('remark', this.form.remark);
    },
    onSubmit() {
      let {
        tenantId,
        type
      } = this.$route.query;
      let {
        projectId
      } = this;
      let params = {
        ...this.form,
        tenantId,
        projectId,
        type
      };
      tenantPayOtherFeeNonHouse({
        ...params
      }).then(res => {
        let result = res.data;
        if (result.code === '10000') {
          let {
            id,
            totalAmount
          } = result.data;
          this.$router.push({
            name: 'paymentIndex',
            query: {
              erjiaOderId: id,
              erjiaTotalAmount: totalAmount
            }
          });
        }
      });
    },
    clean() {
      removeStorage('phone', 'localStorage');
      removeStorage('tenantId', 'localStorage');
      this.$router.push({
        name: 'expenseType'
      });
    }
  }
};