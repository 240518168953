import request from '@utils/request_pay';

/**
 * 获取支付类型
 * */
export const getDictItems = (params = {}, data) => {
  return request.post('/dict/getDictItems', params);
};

/**
 * 获取手机验证码
 * */
export const getMSGCode = (params = {}, data) => {
  return request.get('/tenant/qrcode/getMSGCode', {
    params
  });
};

/**
 * 姓名和身份证后四位验证租客信息
 * */
export const checkMSGCode = (params = {}, data) => {
  return request.get('/tenant/qrcode/checkUserInfo', {
    params
  });
};

/**
 * 查询关联合同信息
 * */
export const getSelectRent = (params = {}, data) => {
  return request.get('/tenant/qrcode/selectRent', {
    params
  });
};

/**
 * 创建订单
 * */
export const tenantPayOtherFee = (params = {}, data) => {
  return request.post('/tenant/qrcode/tenantPayOtherFee', params);
};

/**
 * 支付方式
 * */
export const chosePaymentType = (params = {}, data) => {
  return request.get('/qrCode/chosePaymentType', {
    params
  });
};

/**
 * 查询动态二维码账单信息
 * */
export const paymentData = (params = {}, data) => {
  return request.get('/qrCode/paymentData', {
    params
  });
};

/**
 * 租客自支付固定项目和固定类型
 * */
export const tenantPayOtherFeeNonHouse = (params = {}, data) => {
  return request.post('/tenant/qrcode/tenantPayOtherFeeNonHouse', params);
};

/**
 * 查询项目名称
 * */
export const getProjectName = (params = {}, data) => {
  return request.get('/tenant/qrcode/getProjectName', {
    params
  });
};

/**
 * 查询费用类型名称
 * */
export const selectTypeName = (params = {}, data) => {
  return request.get('/tenant/qrcode/selectTypeName', {
    params
  });
};